@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

body {
  background-color: #2d4a4a;
  color: #fefed2;
  font-family: 'Roboto', sans-serif;
}

#main {
  margin: auto;
  height: 1000px;
  width: 694px;
}
#logo {
  height: 625px;
  margin-top: 20px;
  width: 694px;
  background-image: url("../img/frog_logo.png");
}

#main_text {
  text-align: center;
  font-size: 10em;
  font-family: 'Nanum Pen Script', cursive;
}
